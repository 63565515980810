import React from "react";
import image1 from "../../assets/img/slider/1.jpg";
import image2 from "../../assets/img/slider/2.jpg";
import image3 from "../../assets/img/slider/3.jpg";
import image4 from "../../assets/img/slider/4.jpg";
import whatsapp from "../../assets/img/whatsapp.jpg";
import bannerImage from '../../assets/img/banner.jpg';
import { NavLink } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
function ArmadaCenter() {
  return (
    <div>
     <Sidebar />
      {/* <!-- Main --> */}
      <div id="bauen-main">
        {/* <!-- Content --> */}
        <div className="content-wrapper">
          {/* <!-- Lines --> */}
          <section className="content-lines-wrapper">
            <div className="content-lines-inner">
              <div className="content-lines"></div>
            </div>
          </section>
          <div>
            <a href="https://www.instagram.com/swastika.designers/?igshid=MDM4ZDc5MmU%3D">
              <img src={whatsapp} className="whatsapp" alt="whatspp" />
            </a>
          </div>
          {/* <!-- Header Banner --> */}
          <section
            className="banner-header banner-img valign bg-img bg-fixed"
            data-overlay-darkgray="5"
            style={{
                backgroundImage: `url(${bannerImage})`,
      }}
          ></section>
          {/* <!-- Projects Page --> */}
          <section className="section-padding2">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="section-title2">Armada Center</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <p>
                    Architecture non lorem ac erat suscipit bibendum. Nulla
                    facilisi. Sedeuter nunc volutpat, mollis sapien vel,
                    conseyer turpeutionyer masin libero sempe. Fusceler mollis
                    augue sit amet hendrerit vestibulum. Duisteyerionyer
                    venenatis lacus.
                  </p>
                  <p>
                    Villa gravida eros ut turpis interdum ornare. Interdum et
                    malesu they adamale fames ac anteipsu pimsine faucibus.
                    Curabitur arcu site feugiat in tortor in, volutpat
                    sollicitudin libero.
                  </p>
                </div>
                <div className="col-md-4">
                  <p>
                    <b>Year : </b> 2022
                  </p>
                  <p>
                    <b>Company : </b> Art International
                  </p>
                  <p>
                    <b>Project Name : </b> Armada Center
                  </p>
                  <p>
                    <b>Location : </b> New York, USA
                  </p>
                </div>
              </div>
              <div className="row mt-30">
                <div className="col-md-6 gallery-item">
                  <a href={image1} title="Architecture" className="img-zoom">
                    <div className="gallery-box">
                      <div className="gallery-img">
                        {" "}
                        <img
                          src={image1}
                          className="img-fluid mx-auto d-block"
                          alt="work-img"
                        />{" "}
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 gallery-item">
                  <a href={image2} title="Architecture" className="img-zoom">
                    <div className="gallery-box">
                      <div className="gallery-img">
                        {" "}
                        <img
                          src={image2}
                          className="img-fluid mx-auto d-block"
                          alt="work-img"
                        />{" "}
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 gallery-item">
                  <a href={image3} title="Architecture" className="img-zoom">
                    <div className="gallery-box">
                      <div className="gallery-img">
                        {" "}
                        <img
                          src={image3}
                          className="img-fluid mx-auto d-block"
                          alt="work-img"
                        />{" "}
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 gallery-item">
                  <a href={image4} title="Architecture" className="img-zoom">
                    <div className="gallery-box">
                      <div className="gallery-img">
                        {" "}
                        <img
                          src={image4}
                          className="img-fluid mx-auto d-block"
                          alt="work-img"
                        />{" "}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Prev-Next Projects --> */}
          <section className="projects-prev-next">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-sm-flex align-items-center justify-content-between">
                    <div className="projects-prev-next-left">
                      <NavLink to="/CottonHouse">
                        {" "}
                        <i className="ti-arrow-left"></i> Previous Project
                      </NavLink>
                    </div>
                    <a href="#projects">
                      <i className="ti-layout-grid3-alt"></i>
                    </a>
                    <div className="projects-prev-next-right">
                      <NavLink to="/StonyaVilla">
                        Next Project <i className="ti-arrow-right"></i>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
         <Footer />
        </div>
      </div>
    </div>
  );
}

export default ArmadaCenter;
