import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import image from "../../assets/img/slider/1.jpg";
import img from "../../assets/img/slider/2.jpg";
import whatsapp from "../../assets/img/whatsapp.jpg";
import about from "../../assets/img/about.jpg";
import service1 from "../../assets/img/projects/1.jpg";
import service2 from "../../assets/img/projects/2.jpg";
import service3 from "../../assets/img/projects/3.jpg";
import service4 from "../../assets/img/projects/4.jpg";
import icon1 from "../../assets/img/icons/icon-1.png";
import icon2 from "../../assets/img/icons/icon-2.png";
import icon3 from "../../assets/img/icons/icon-3.png";
import { NavLink } from "react-router-dom";
import Testimonials from "../testimonials/Testimonials";
import Client from "../client/Client";
import Footer from "../footer/Footer";
import Sidebar from "../sidebar/Sidebar";

function Home() {
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  const responsive = {
    0: { items: 1 },
    768: { items: 2 },
    1024: { items: 2 },
  };
  const [image1, setImage1] = useState(`${image}`);
  const [image2, setImage2] = useState(`${img}`);
  const [text, setText] = useState("Innovative Design");
  const [showImage1, setShowImage1] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showImage1) {
        setImage2(`${img}`);
        setText("Innovate Design");
      } else {
        setImage1(`${image}`);
        setText("Innovative Design");
      }
      setShowImage1((prevState) => !prevState);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [showImage1]);
  return (
    <div>
     <Sidebar />
      {/*   <!-- Main --> */}
      <div id="bauen-main">
        {/* <!-- Slider --> */}
        <header id="home" className="header slider-fade">
          <div className="text-right item bg-img" data-overlay-dark="3">
            <img src={showImage1 ? image1 : image2} alt="background" style={{minHeight:"100vh"}} />
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-7 offset-md-5">
                    <div className="o-hidden">
                      <h1>{text}</h1>
                      <div className="butn-light mt-30 mb-30">
                        <a href="/contact">
                          <span>Contact us</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*   <!-- Corner --> */}
          <div className="hero-corner"></div>
          <div className="hero-corner3"></div>
          <div>
            <a href="https://www.instagram.com/swastika.designers/?igshid=MDM4ZDc5MmU%3D">
              <img src={whatsapp} className="whatsapp" alt="whatspp" />
            </a>
          </div>
        </header>
        {/*     <!-- Content --> */}
        <div className="content-wrapper">
          {/*   <!-- Lines --> */}
          <section className="content-lines-wrapper">
            <div className="content-lines-inner">
              <div className="content-lines"></div>
            </div>
          </section>
          {/*   <!-- About --> */}

          <section
            id="about"
            className={`about section-padding ${isDesktop && "desktop-margin"}`}
          >
            <div className="container">
              <div className="row">
                <div
                  className="col-md-6 mb-30 animate-box"
                  data-animate-effect="fadeInUp"
                >
                  <h2 className="section-title">
                    About <span>Swastika Designers</span>
                  </h2>
                  <p>
                    Swastika Designers believes beautiful building results from
                    the collective determination of client, architect and
                    builder. The happiness of our clients is our singular goal.
                    We believe that every project has a mission and we
                    collaborate with our clients throughout the design process —
                    from early visioning through project completion — to develop
                    and implement a shared vision to achieve that mission.
                  </p>
                  <p>
                    We are committed to provide the highest level of client
                    services, maintaining clear and consistent communication,
                    proactively anticipating client needs, and endeavouring to
                    make the process of working with us as smooth and efficient
                    as possible. Our studio practice focuses on bringing
                    innovative design by also being remain committed to our
                    cultural values with economic and social integrity. We also
                    keep emerging the trends in the architecture and design
                    spaces.
                  </p>
                  <p align="justified">
                    Its works include Industrial, Commercial, Residential,
                    Hospitality as well as Educational, Healthcare, Retail and
                    Interior projects. As specialists in Sustainability,
                    Landscape designing, and Urban designing, we create
                    buildings and spaces that focuses on the satisfaction of the
                    clients by providing comfortable spaces as well as the
                    successful and efficient delivery of completed projects. We
                    value the relationships he builds with his clients and the
                    influence his work will have on their daily lives.
                  </p>
                </div>
                <div
                  className="col-md-6 animate-box"
                  data-animate-effect="fadeInUp"
                >
                  <div className="about-img">
                    <div className="img">
                      <img src={about} className="img-fluid" alt="not found" />
                    </div>

                    <div
                      className="about-img-2 about-buro"
                      style={{ width: "200px" }}
                    >
                      Restaurant in Delhi Ncr
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*       <!-- Projects --> */}
          <section id="projects" className="projects section-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="section-title">
                    Our <span>Projects</span>
                  </h2>
                </div>
              </div>
              <AliceCarousel
                responsive={responsive}
                autoPlay
                autoPlayInterval={2000}
                buttonsDisabled={true}
              >
                <div className="item">
                  <div className="position-re o-hidden">
                    <img src={service1} alt="not found" />
                  </div>
                  <div className="con">
                    <h6>
                     
                      <NavLink to="/cotton-house">Interior</NavLink>
                    </h6>
                    <div className="line"></div>
                    <NavLink to="/cotton-house">
                      <i className="ti-arrow-right"></i>
                    </NavLink>
                  </div>
                </div>
                <div className="item">
                  <div className="position-re o-hidden">
                    <img src={service2} alt="not found" />
                  </div>
                  <div className="con">
                    <h6>
                      <NavLink to="/armada-center">Exterior</NavLink>
                    </h6>
                    <h5>
                      <NavLink to="/armada-center">Armada Center</NavLink>
                    </h5>
                    <div className="line"></div>
                    <NavLink to="/armada-center">
                      <i className="ti-arrow-right"></i>
                    </NavLink>
                  </div>
                </div>
                <div className="item">
                  <div className="position-re o-hidden">
                    <img src={service3} alt="not found" />
                  </div>
                  <div className="con">
                    <h6>
                      <NavLink to="/stonya-villa">Urban</NavLink>
                    </h6>
                    <h5>
                      <NavLink to="/StonyaVilla">Stonya Villa</NavLink>
                    </h5>
                    <div className="line"></div>
                    <NavLink to="/stonya-villa">
                      <i className="ti-arrow-right"></i>
                    </NavLink>
                  </div>
                </div>
                <div className="item">
                  <div className="position-re o-hidden">
                    <img src={service4} alt="not found" />
                  </div>
                  <div className="con">
                    <h6>
                      <NavLink to="/prime-hotel">Interior</NavLink>
                    </h6>
                    <h5>
                      <NavLink to="/prime-hotel">Prime Hotel</NavLink>
                    </h5>
                    <div className="line"></div>
                    <NavLink to="/prime-hotel">
                      <i className="ti-arrow-right"></i>
                    </NavLink>
                  </div>
                </div>
              </AliceCarousel>
            </div>
          </section>
          {/*   <!-- Services --> */}
          <section id="services" className="services section-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="section-title">
                    Our <span>Services</span>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="item">
                    <NavLink to="/architecture">
                      <img src={icon1} alt="not found" />
                      <h5>Architecture</h5>
                      <div className="line"></div>
                      <p>
                        Architecture bibendum eros eget lacus the vulputate sit
                        amet vehicuta nibhen ulicera in the vitae miss.
                      </p>
                      <div className="numb">01</div>
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <NavLink to="/interior-design">
                      <img src={icon2} alt="not found" />
                      <h5>Interior Design</h5>
                      <div className="line"></div>
                      <p>
                        Architecture bibendum eros eget lacus the vulputate sit
                        amet vehicuta nibhen ulicera in the vitae miss.
                      </p>
                      <div className="numb">02</div>
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <NavLink to="/urban-design">
                      <img src={icon3} alt="not found" />
                      <h5>Urban Design</h5>
                      <div className="line"></div>
                      <p>
                        Architecture bibendum eros eget lacus the vulputate sit
                        amet vehicuta nibhen ulicera in the vitae miss.
                      </p>
                      <div className="numb">03</div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*     <!-- Blog --> */}
          <section id="blog" className="bauen-blog section-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="section-title">
                    Current <span>News</span>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="owl-carousel owl-theme">
                    <AliceCarousel
                      responsive={responsive}
                      autoPlay
                      autoPlayInterval={2000}
                      buttonsDisabled={true}
                    >
                      <div className="item">
                        <div className="position-re o-hidden">
                          <img src={service1} alt="not found" />
                        </div>
                        <div className="con">
                          <span className="category">
                            <NavLink to="/blog">Architecture </NavLink> -
                            20.12.2022
                          </span>
                          <h5>
                            <NavLink to="/post">
                              Modern Architectural Structures
                            </NavLink>
                          </h5>
                        </div>
                      </div>
                      <div className="item">
                        <div className="position-re o-hidden">
                          <img src={service2} alt="not found" />
                        </div>
                        <div className="con">
                          <span className="category">
                            <NavLink to="/blog">Interior</NavLink> - 18.12.2022
                          </span>
                          <h5>
                            <NavLink to="/post1">
                              Modernism in Architecture
                            </NavLink>
                          </h5>
                        </div>
                      </div>
                      <div className="item">
                        <div className="position-re o-hidden">
                          <img src={service3} alt="not found" />
                        </div>
                        <div className="con">
                          <span className="category">
                            <NavLink to="/blog">Urban</NavLink> - 16.12.2022
                          </span>
                          <h5>
                            <NavLink to="/post2">
                              Postmodern Architecture
                            </NavLink>
                          </h5>
                        </div>
                      </div>
                      <div className="item">
                        <div className="position-re o-hidden">
                          <img src={service4} alt="not found" />
                        </div>
                        <div className="con">
                          <span className="category">
                            <NavLink to="/blog">Planing</NavLink> - 14.12.2022
                          </span>
                          <h5>
                            <NavLink to="/post3">
                              Modern Architecture Buildings
                            </NavLink>
                          </h5>
                        </div>
                      </div>
                    </AliceCarousel>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*   <!-- Contact --> */}
          <section
            id="contact"
            className="section-padding"
            data-scroll-index="5"
          >
            <div className="container">
              <div className="row">
                <div
                  className="col-md-12 animate-box"
                  data-animate-effect="fadeInUp"
                >
                  <h2 className="section-title">
                    Contact <span>Us</span>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-4 mb-30 animate-box"
                  data-animate-effect="fadeInUp"
                >
                  <p>
                    <b>Phone :</b> +91 7060858637
                  </p>
                  <p>
                    <b>Email :</b> info@swastikadesigners.in
                  </p>
                </div>
                <div
                  className="col-md-4 mb-30 animate-box"
                  data-animate-effect="fadeInUp"
                >
                  <p>
                    <b>Address :</b> 618, Shiv Mandir Ln, Jawahar Quarters,
                    BEGUM BAGH, Meerut, Uttar Pradesh, 250001
                    <br />
                    Circular road, opp. Ch. Chottu Ram Inter College,
                    Muzaffarnagar, 251001
                  </p>
                </div>
                <div
                  className="col-md-4 animate-box"
                  data-animate-effect="fadeInUp"
                >
                  <form method="post" className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required=""
                      />
                    </div>
                    <div className="col-md-12">
                      <textarea
                        name="message"
                        id="message"
                        cols="40"
                        rows="3"
                        placeholder="Your Message"
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button className="butn-dark mt-15">
                        <a href="#0">
                          <span>Send</span>
                        </a>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          {/*  <!-- Promo video - Testiominals --> */}
          <section className="testimonials">
            <div
              className="background bg-img bg-fixed section-padding pb-0"
              data-background="img/banner2.jpg"
              data-overlay-dark="3"
            >
              <div className="container">
                <div className="row">
                  {/* <!-- Promo video --> */}
                  <div className="col-md-6">
                    <div className="vid-area">
                      <div className="vid-icon">
                        <a
                          className="play-button vid"
                          href="https://youtu.be/RziCmLzpFNY"
                        >
                          <svg className="circle-fill">
                            <circle
                              cx="43"
                              cy="43"
                              r="39"
                              stroke="#fff"
                              strokeWidth=".5"
                            ></circle>
                          </svg>
                          <svg className="circle-track">
                            <circle
                              cx="43"
                              cy="43"
                              r="39"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                            ></circle>
                          </svg>
                          <span className="polygon">
                            <i className="ti-control-play"></i>
                          </span>
                        </a>
                      </div>
                      <div className="cont mt-15 mb-30">
                        <h5>View promo video</h5>
                      </div>
                    </div>
                  </div>
                  {/*   <!-- Testiominals --> */}
                  <div className="col-md-5 offset-md-1">
                   
                    <Testimonials />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*      <!-- Clients --> */}
          <Client />
        
          {/*     <!-- Footer --> */}
         <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home;
