import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import { NavLink } from "react-router-dom";
import whatsapp from "../../assets/img/whatsapp.jpg";
import service1 from "../../assets/img/projects/1.jpg";
import service2 from "../../assets/img/projects/2.jpg";
import service3 from "../../assets/img/projects/3.jpg";
import service4 from "../../assets/img/projects/4.jpg";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";

const Project = () => {
    const responsive = {
        0: { items: 1 },
        768: { items: 2 },
        1024: { items: 2 },
      };
  return (
    <>
      <Sidebar />
      <div id="bauen-main">
      <div>
            <a href="https://www.instagram.com/swastika.designers/?igshid=MDM4ZDc5MmU%3D">
              <img src={whatsapp} className="whatsapp" alt="whatspp" />
            </a>
          </div>
        <section id="projects" className="projects section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title">
                  Our <span>Projects</span>
                </h2>
              </div>
            </div>
            <AliceCarousel
              responsive={responsive}
              autoPlay
              autoPlayInterval={2000}
              buttonsDisabled={true}
            >
              <div className="item">
                <div className="position-re o-hidden">
                  <img src={service1} alt="not found" />
                </div>
                <div className="con">
                  <h6>
                    <NavLink to="/cotton-house">Interior</NavLink>
                  </h6>
                  <div className="line"></div>
                  <NavLink to="/cotton-house">
                    <i className="ti-arrow-right"></i>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="position-re o-hidden">
                  <img src={service2} alt="not found" />
                </div>
                <div className="con">
                  <h6>
                    <NavLink to="/armada-center">Exterior</NavLink>
                  </h6>
                  <h5>
                    <NavLink to="/armada-center">Armada Center</NavLink>
                  </h5>
                  <div className="line"></div>
                  <NavLink to="/armada-center">
                    <i className="ti-arrow-right"></i>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="position-re o-hidden">
                  <img src={service3} alt="not found" />
                </div>
                <div className="con">
                  <h6>
                    <NavLink to="/stonya-villa">Urban</NavLink>
                  </h6>
                  <h5>
                    <NavLink to="/StonyaVilla">Stonya Villa</NavLink>
                  </h5>
                  <div className="line"></div>
                  <NavLink to="/stonya-villa">
                    <i className="ti-arrow-right"></i>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="position-re o-hidden">
                  <img src={service4} alt="not found" />
                </div>
                <div className="con">
                  <h6>
                    <NavLink to="/prime-hotel">Interior</NavLink>
                  </h6>
                  <h5>
                    <NavLink to="/prime-hotel">Prime Hotel</NavLink>
                  </h5>
                  <div className="line"></div>
                  <NavLink to="/prime-hotel">
                    <i className="ti-arrow-right"></i>
                  </NavLink>
                </div>
              </div>
            </AliceCarousel>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Project;
