import React from "react";
import { useMediaQuery } from "react-responsive";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import logo from "../../assets/img/logo.png";
import whatsapp from "../../assets/img/whatsapp.jpg";
import about from "../../assets/img/about.jpg";
import service1 from "../../assets/img/projects/1.jpg";
import service2 from "../../assets/img/projects/2.jpg";
import service3 from "../../assets/img/projects/3.jpg";
import service4 from "../../assets/img/projects/4.jpg";
import icon1 from "../../assets/img/icons/icon-1.png";
import icon2 from "../../assets/img/icons/icon-2.png";
import icon3 from "../../assets/img/icons/icon-3.png";
import { NavLink } from "react-router-dom";
import Testimonials from "../testimonials/Testimonials";
import Client from "../client/Client";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";

function About() {
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  const responsive = {
    0: { items: 1 },
    768: { items: 2 },
    1024: { items: 2 },
  };

  return (
    <div>
    <Sidebar />
      {/*   <!-- Main --> */}
      <div id="bauen-main">
        {/*     <!-- Content --> */}
        <div className="content-wrapper">
          {/*   <!-- Lines --> */}
        
          <div>
            <a href="https://www.instagram.com/swastika.designers/?igshid=MDM4ZDc5MmU%3D">
              <img src={whatsapp} className="whatsapp" alt="whatspp" />
            </a>
          </div>
          {/*   <!-- About --> */}

          <section
            id="about"
            className={`about section-padding ${isDesktop && "desktop-margin"}`}
          >
            <div className="container">
              <div className="row">
                <div
                  className="col-md-6 mb-30 animate-box"
                  data-animate-effect="fadeInUp"
                >
                  <h2 className="section-title">
                    About <span>Swastika Designers</span>
                  </h2>
                  <p>
                    Swastika Designers believes beautiful building results from
                    the collective determination of client, architect and
                    builder. The happiness of our clients is our singular goal.
                    We believe that every project has a mission and we
                    collaborate with our clients throughout the design process —
                    from early visioning through project completion — to develop
                    and implement a shared vision to achieve that mission.
                  </p>
                  <p>
                    We are committed to provide the highest level of client
                    services, maintaining clear and consistent communication,
                    proactively anticipating client needs, and endeavouring to
                    make the process of working with us as smooth and efficient
                    as possible. Our studio practice focuses on bringing
                    innovative design by also being remain committed to our
                    cultural values with economic and social integrity. We also
                    keep emerging the trends in the architecture and design
                    spaces.
                  </p>
                  <p align="justified">
                    Its works include Industrial, Commercial, Residential,
                    Hospitality as well as Educational, Healthcare, Retail and
                    Interior projects. As specialists in Sustainability,
                    Landscape designing, and Urban designing, we create
                    buildings and spaces that focuses on the satisfaction of the
                    clients by providing comfortable spaces as well as the
                    successful and efficient delivery of completed projects. We
                    value the relationships he builds with his clients and the
                    influence his work will have on their daily lives.
                  </p>
                </div>
                <div
                  className="col-md-6 animate-box"
                  data-animate-effect="fadeInUp"
                >
                  <div className="about-img">
                    <div className="img">
                      <img src={about} className="img-fluid" alt="not found" />
                    </div>

                    <div
                      className="about-img-2 about-buro"
                      style={{ width: "200px" }}
                    >
                      Restaurant in Delhi Ncr
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
         
        
        <Footer />
        </div>
      </div>
    </div>
  );
}

export default About;
