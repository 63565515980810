import React from 'react';
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import client1 from "../../assets/img/clients/1.png";
import client2 from "../../assets/img/clients/2.png";
import client3 from "../../assets/img/clients/3.png";
import client4 from "../../assets/img/clients/4.png";
import client5 from "../../assets/img/clients/5.png";
import client6 from "../../assets/img/clients/6.png";

const Client = () => {
    const responsive = {
        0: { items: 1 },
        1024: { items: 3 },
      };
    return (
        <section className="clients">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
            <AliceCarousel
                  responsive={responsive}
                  autoPlay
                  autoPlayInterval={2000}
                  buttonsDisabled={true}
                  dotsDisabled={true}
                  className="custom-carousel" 
                >
              <div className="clients-logo">
                <a href="#0">
                  <img src={client1} alt="not found" />
                </a>
              </div>
              <div className="clients-logo">
                <a href="#0">
                  <img src={client2} alt="not found" />
                </a>
              </div>
              <div className="clients-logo">
                <a href="#0">
                  <img src={client3} alt="not found" />
                </a>
              </div>
              <div className="clients-logo">
                <a href="#0">
                  <img src={client4} alt="not found" />
                </a>
              </div>
              <div className="clients-logo">
                <a href="#0">
                  <img src={client5} alt="not found" />
                </a>
              </div>
              <div className="clients-logo">
                <a href="#0">
                  <img src={client6} alt="not found" />
                </a>
              </div>
              </AliceCarousel>
            </div>
          </div>
        </div>
      </section>
    );
}


export default Client;