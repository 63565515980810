import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import CottonHouse from "./components/projects/CottonHouse";
import ArmadaCenter from "./components/projects/ArmadaCenter";
import PrimeHotel from "./components/projects/PrimeHotel";
import StonyaVilla from "./components/projects/StonyaVilla";
import InteriorDesign from "./components/Services/InteriorDesign";
import Architecture from "./components/Services/Architecture";
import Modelling from "./components/Services/Modelling";
import Planning from "./components/Services/Planning";
import DecorPlan from "./components/Services/DecorPlan";
import UrbanDesign from "./components/Services/UrbanDesign";
import Service from "./components/Services/Service";
import Project from "./components/projects/Project";
import Contact from "./components/contact/Contact";
import Blog from "./components/Blog/Blog";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Post from "./components/Blog/Post";
import Post2 from "./components/Blog/Post2";
import Post3 from "./components/Blog/Post3";
import Post4 from "./components/Blog/Post4";
import "./App.css";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/project" element={<Project />} />
          <Route path="/service" element={<Service />} />
          <Route path="/cotton-house" element={<CottonHouse />} />
          <Route path="/armada-center" element={<ArmadaCenter />} />
          <Route path="/prime-hotel" element={<PrimeHotel />} />
          <Route path="/stonya-villa" element={<StonyaVilla />} />
          <Route path="/architecture" element={<Architecture />} />
          <Route path="/decor-plan" element={<DecorPlan />} />
          <Route path="/interior-design" element={<InteriorDesign />} />
          <Route path="/modelling" element={<Modelling />} />
          <Route path="/planning" element={<Planning />} />
          <Route path="/urban-design" element={<UrbanDesign />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/post" element={<Post />} />
          <Route path="/post2" element={<Post2 />} />
          <Route path="/post3" element={<Post3 />} />
          <Route path="/post4" element={<Post4 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
