import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { NavLink } from "react-router-dom";
import image from "../../assets/img/slider/1.jpg";
import img from "../../assets/img/slider/2.jpg";
import img1 from "../../assets/img/slider/3.jpg";
import img2 from "../../assets/img/slider/4.jpg";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import whatsapp from "../../assets/img/whatsapp.jpg";
import bannerImage from "../../assets/img/banner.jpg";

function Blog() {
  const responsive = {
    0: { items: 1 },
    768: { items: 2 },
    1024: { items: 2 },
  };
  return (
    <div>
      <Sidebar />
      {/* <!-- Main --> */}
      <div id="bauen-main">
        {/* <!-- Content --> */}
        <div className="content-wrapper">
          {/* <!-- Lines --> */}
          <section className="content-lines-wrapper">
            <div className="content-lines-inner">
              <div className="content-lines"></div>
            </div>
          </section>
          <div>
            <a href="https://www.instagram.com/swastika.designers/?igshid=MDM4ZDc5MmU%3D">
              <img src={whatsapp} className="whatsapp" alt="whatspp" />
            </a>
          </div>
          {/* <!-- Header Banner --> */}
          <section
            className="banner-header banner-img valign bg-img bg-fixed"
            data-overlay-darkgray="5"
            style={{
              backgroundImage: `url(${bannerImage})`,
            }}
          ></section>
          {/* <!-- Blog  --> */}
          <section className="bauen-blog section-padding2">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="section-title">
                    Our <span>Blog</span>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="owl-carousel owl-theme">
                    <AliceCarousel
                      responsive={responsive}
                      autoPlay
                      autoPlayInterval={2000}
                      buttonsDisabled={true}
                    >
                      <div className="item">
                        <div className="position-re o-hidden">
                          <img src={image} alt="not found" />
                        </div>
                        <div className="con">
                          <span className="category">
                            <NavLink to="/blog">Architecture </NavLink> -
                            20.12.2022
                          </span>
                          <h5>
                            <NavLink to="/post">
                              Modern Architectural Structures
                            </NavLink>
                          </h5>
                        </div>
                      </div>

                      <div className="item">
                        <div className="position-re o-hidden">
                          <img src={img} alt="not found" />
                        </div>
                        <div className="con">
                          {" "}
                          <span className="category">
                            <NavLink to="/blog">Interior</NavLink> - 18.12.2022
                          </span>
                          <h5>
                            <NavLink to="/post2">
                              Modernism in Architecture
                            </NavLink>
                          </h5>
                        </div>
                      </div>

                      <div className="item">
                        <div className="position-re o-hidden">
                          <img src={img1} alt="not found" />
                        </div>
                        <div className="con">
                          {" "}
                          <span className="category">
                            <NavLink to="/blog">Urban</NavLink> - 16.12.2022
                          </span>
                          <h5>
                            <NavLink to="/post3">
                              Postmodern Architecture
                            </NavLink>
                          </h5>
                        </div>
                      </div>

                      <div className="item">
                        <div className="position-re o-hidden">
                          <img src={img2} alt="not found" />
                        </div>
                        <div className="con">
                          {" "}
                          <span className="category">
                            <NavLink to="/blog">Planing</NavLink> - 14.12.2022
                          </span>
                          <h5>
                            <NavLink to="/post4">
                              Modern Architecture Buildings
                            </NavLink>
                          </h5>
                        </div>
                      </div>
                    </AliceCarousel>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-md-12 text-center">
                 
                  <ul className="bauen-pagination-wrap align-center mb-30 mt-30">
                    <li>
                      <NavLink to="Blog">
                        <i className="ti-angle-left"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/blog">1</NavLink>
                    </li>
                    <li>
                      <NavLink to="blog" className="active">
                        2
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/blog">3</NavLink>
                    </li>
                    <li>
                      <NavLink to="/blog">
                        <i className="ti-angle-right"></i>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Blog;
