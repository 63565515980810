import React from 'react';
import whatsapp from "../../assets/img/whatsapp.jpg";
import Footer from '../footer/Footer';
import Sidebar from '../sidebar/Sidebar';

const Contact = () => {
    return (
        <>
        <Sidebar />
        <div id="bauen-main">
        <div>
            <a href="https://www.instagram.com/swastika.designers/?igshid=MDM4ZDc5MmU%3D">
              <img src={whatsapp} className="whatsapp" alt="whatspp" />
            </a>
          </div>
        <section
        id="contact"
        className="section-padding"
        data-scroll-index="5"
      >
        <div className="container">
          <div className="row">
            <div
              className="col-md-12 animate-box"
              data-animate-effect="fadeInUp"
            >
              <h2 className="section-title">
                Contact <span>Us</span>
              </h2>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-4 mb-30 animate-box"
              data-animate-effect="fadeInUp"
            >
              <p>
                <b>Phone :</b> +91 7060858637
              </p>
              <p>
                <b>Email :</b> info@swastikadesigners.in
              </p>
            </div>
            <div
              className="col-md-4 mb-30 animate-box"
              data-animate-effect="fadeInUp"
            >
              <p>
                <b>Address :</b> 618, Shiv Mandir Ln, Jawahar Quarters,
                BEGUM BAGH, Meerut, Uttar Pradesh, 250001
                <br />
                Circular road, opp. Ch. Chottu Ram Inter College,
                Muzaffarnagar, 251001
              </p>
            </div>
            <div
              className="col-md-4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <form method="post" className="row">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Full Name"
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    required=""
                  />
                </div>
                <div className="col-md-12">
                  <textarea
                    name="message"
                    id="message"
                    cols="40"
                    rows="3"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div className="col-md-12">
                  <button className="butn-dark mt-15">
                    <a href="#0">
                      <span>Send</span>
                    </a>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      </div>
      </>
    );
}


export default Contact;