import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import team1 from "../../assets/img/team/1.jpg";
import team2 from "../../assets/img/team/2.jpg";
import team4 from "../../assets/img/team/4.jpg";
import quto from "../../assets/img/quot.png";

const responsive = {
  0: { items: 1 },
};

const Testimonials = () => {
  return (
    <div
      className="testimonials-box animate-box"
      data-animate-effect="fadeInUp"
    >
      <div className="head-box">
        <h4>What Client's Say?</h4>
      </div>
      <div className="carousel-container">
        <AliceCarousel
          responsive={responsive}
          autoPlay
          autoPlayInterval={2000}
          buttonsDisabled={true}
        >
          <div className="item">
            <span className="quote">
              <img src={quto} alt="not found" />
            </span>
            <p>
              Architect dapibus augue metus the nec feugiat erat hendrerit nec.
              Duis ve ante the lemon sanleo nec feugiat erat hendrerit necuis ve
              ante.
            </p>
            <div className="info">
              <div className="author-img">
                <img src={team1} alt="not found" />
              </div>
              <div className="cont">
                <h6>Jason Brown</h6>
                <span>Crowne Plaza Owner</span>
              </div>
            </div>
          </div>
          <div className="item">
            <span className="quote">
              <img src={quto} alt="not found" />
            </span>
            <p>
              Interior dapibus augue metus the nec feugiat erat hendrerit nec.
              Duis ve ante the lemon sanleo nec feugiat erat hendrerit necuis ve
              ante.
            </p>
            <div className="info">
              <div className="author-img">
                <img src={team2} alt="not found" />
              </div>
              <div className="cont">
                <h6>Emily White</h6>
                <span>Armada Owner</span>
              </div>
            </div>
          </div>
          <div className="item">
            <span className="quote">
              <img src={quto} alt="not found" />
            </span>
            <p>
              Urban dapibus augue metus the nec feugiat erat hendrerit nec. Duis
              ve ante the lemon sanleo nec feugiat erat hendrerit necuis ve
              ante.
            </p>
            <div className="info">
              <div className="author-img">
                <img src={team4} alt="not found" />
              </div>
              <div className="cont">
                <h6>Jesica Smith</h6>
                <span>Alsa Manager</span>
              </div>
            </div>
          </div>
        </AliceCarousel>
      </div>
    </div>
  );
};

export default Testimonials;
