import React,{ useState} from 'react';
import logo from "../../assets/img/logo.png";
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  const [isAsideActive, setAsideActive] = useState(false);


  const toggleAside = () => {
    console.log('Toggling aside:', isAsideActive);
    setAsideActive(!isAsideActive);
  };


  const asideClasses = `bauen-aside ${isAsideActive ? 'active' : ''}`;
    return (
        <>
        <div id="preloader"></div>
      {/*  <!-- Sidebar Section --> */}
      <a href="#"
      className={`js-bauen-nav-toggle bauen-nav-toggle ${isAsideActive ? 'active' : ''}`}
      onClick={toggleAside}
      >
        <i></i>
      </a>
      <aside id="bauen-aside" 
      className={asideClasses}
      >
        {/* <!-- Logo --> */}
        <div className="bauen-logo">
          <NavLink to="/">
            {" "}
            <img
              src={logo}
              className="logo-img"
              alt="not found"
            />
          </NavLink>
        </div>
        {/* <!-- Menu --> */}
        <nav className="bauen-main-menu">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/project">Projects</a>
            </li>
            <li className="active">
              <a href="/service">Services</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </nav>
        {/* <!-- Sidebar Footer --> */}
        <div className="bauen-footer">
          <ul>
            <li>
              <a href="#">
                <i className="ti-facebook"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="ti-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="ti-instagram"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="ti-pinterest"></i>
              </a>
            </li>
          </ul>
        </div>
      </aside>
      </>
    );
}



export default Sidebar;