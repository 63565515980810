import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
      <footer className="main-footer dark">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-30">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Phone</h6>
              </div>
              <p>+91 7060858637</p>
            </div>
          </div>
          <div className="col-md-4 mb-30">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Email</h6>
              </div>
              <p>info@swastikadesigners.in</p>
            </div>
          </div>
          <div className="col-md-4 mb-30">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Our Address</h6>
              </div>
              <p>
                618, Shiv Mandir Ln, Jawahar Quarters, BEGUM BAGH, Meerut,
                Uttar Pradesh, 250001
                <br />
                <br />
                Circular road, opp. Ch. Chottu Ram Inter College,
                muzaffarnagar, 251001
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="text-left">
                <p>© 2022 Swastika Designers. All right reserved.</p>
              </div>
            </div>
            <div className="col-md-4 abot">
              <div className="social-icon">
                <NavLink to="/swastika">
                  <i className="ti-facebook"></i>
                </NavLink>
                <NavLink to="/swastika">
                  <i className="ti-twitter"></i>
                </NavLink>
                <NavLink to="/swastika">
                  <i className="ti-instagram"></i>
                </NavLink>
                <NavLink to="/swastika">
                  <i className="ti-pinterest"></i>
                </NavLink>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
    </footer>
    );
}



export default Footer;