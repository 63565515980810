import React from "react";
import whatsapp from "../../assets/img/whatsapp.jpg";
import icon1 from "../../assets/img/icons/icon-1.png";
import icon2 from "../../assets/img/icons/icon-2.png";
import icon3 from "../../assets/img/icons/icon-3.png";
import { NavLink } from "react-router-dom";
import Footer from "../footer/Footer";
import Sidebar from "../sidebar/Sidebar";

const Service = () => {
  return (
    <>
      <Sidebar />
      {/*   <!-- Main --> */}
      <div id="bauen-main">
      <div>
            <a href="https://www.instagram.com/swastika.designers/?igshid=MDM4ZDc5MmU%3D">
              <img src={whatsapp} className="whatsapp" alt="whatspp" />
            </a>
          </div>
        <section id="services" className="services section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title">
                  Our <span>Services</span>
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="item">
                  <NavLink to="/architecture">
                    <img src={icon1} alt="not found" />
                    <h5>Architecture</h5>
                    <div className="line"></div>
                    <p>
                      Architecture bibendum eros eget lacus the vulputate sit
                      amet vehicuta nibhen ulicera in the vitae miss.
                    </p>
                    <div className="numb">01</div>
                  </NavLink>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <NavLink to="/interior-design">
                    <img src={icon2} alt="not found" />
                    <h5>Interior Design</h5>
                    <div className="line"></div>
                    <p>
                      Architecture bibendum eros eget lacus the vulputate sit
                      amet vehicuta nibhen ulicera in the vitae miss.
                    </p>
                    <div className="numb">02</div>
                  </NavLink>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <NavLink to="/urban-design">
                    <img src={icon3} alt="not found" />
                    <h5>Urban Design</h5>
                    <div className="line"></div>
                    <p>
                      Architecture bibendum eros eget lacus the vulputate sit
                      amet vehicuta nibhen ulicera in the vitae miss.
                    </p>
                    <div className="numb">03</div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Service;
