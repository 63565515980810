import React from "react";
import { NavLink } from "react-router-dom";
import service1 from "../../assets/img/services/1.jpg";
import service2 from "../../assets/img/services/2.jpg";
import service3 from "../../assets/img/services/3.jpg";
import whatsapp from "../../assets/img/whatsapp.jpg";
import bannerImage from "../../assets/img/banner.jpg";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";

function InteriorDesign() {
  return (
    <div>
      <Sidebar />
      {/* <!-- Main --> */}
      <div id="bauen-main">
        {/* <!-- Content --> */}
        <div className="content-wrapper">
          {/* <!-- Lines --> */}
          <section className="content-lines-wrapper">
            <div className="content-lines-inner">
              <div className="content-lines"></div>
            </div>
          </section>
          <div>
            <a href="https://www.instagram.com/swastika.designers/?igshid=MDM4ZDc5MmU%3D">
              <img src={whatsapp} className="whatsapp" alt="whatspp" />
            </a>
          </div>
          {/* <!-- Header Banner --> */}
          <section
            className="banner-header banner-img valign bg-img bg-fixed"
            data-overlay-darkgray="5"
            style={{
              backgroundImage: `url(${bannerImage})`,
            }}
          ></section>
          {/* <!-- Services Page --> */}
          <section className="section-padding2">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="section-title2">Interior Design</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <p>
                    Working from a deep understanding of our clients’ vision,
                    mission and culture we design with a hospitality approach
                    influenced by the comfort of domesticity while curating
                    spaces for engagement. We believe the best spaces are truly
                    individual and a reflection of you or your business.
                  </p>
                  <p>
                    Beginning with research and inspiration, we move through the
                    stages of design to find out the most efficient, functional,
                    and an elegant solution, while adhering to practical
                    measures such as budget, accessibility and timeline. From a
                    complete makeover, one special piece to finish a room, or a
                    blank canvas in a new building, we ensure you make the most
                    of your investment.
                  </p>
                  <div className="row mb-30">
                    <div className="col-md-6 gallery-item">
                      <a
                        href={service1}
                        title="Interior Design"
                        className="img-zoom"
                      >
                        <div className="gallery-box">
                          <div className="gallery-img">
                            {" "}
                            <img
                              src={service1}
                              className="img-fluid mx-auto d-block"
                              alt="work-img"
                            />{" "}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 gallery-item">
                      <a
                        href={service3}
                        title="Interior Design"
                        className="img-zoom"
                      >
                        <div className="gallery-box">
                          <div className="gallery-img">
                            {" "}
                            <img
                              src={service3}
                              className="img-fluid mx-auto d-block"
                              alt="work-img"
                            />{" "}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-12 gallery-item">
                      <a
                        href={service2}
                        title="Interior Design"
                        className="img-zoom"
                      >
                        <div className="gallery-box">
                          <div className="gallery-img">
                            {" "}
                            <img
                              src={service2}
                              className="img-fluid mx-auto d-block"
                              alt="work-img"
                            />{" "}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 sidebar-side">
                  <aside className="sidebar blog-sidebar">
                    <div className="sidebar-widget services">
                      <div className="widget-inner">
                        <div className="sidebar-title">
                          <h4>All Services</h4>
                        </div>
                        <ul>
                          <li>
                            <NavLink to="/architecture">Architecture</NavLink>
                          </li>
                          <li className="active">
                            <NavLink to="/interior-design">
                              Interior Design
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/urban-design">Urban Design</NavLink>
                          </li>
                          <li>
                            <NavLink to="/planning">Planning</NavLink>
                          </li>
                          <li>
                            <NavLink to="/modelling">3D Modelling</NavLink>
                          </li>
                          <li>
                            <NavLink to="/decor-plan">Decor Plan</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default InteriorDesign;
